import GoogleLogin from 'react-google-login'
import { useContext } from 'react'
import domain from '../constants/domain'
import UserContext from '../contexts/UserContext'

const googleClientId = '413137058312-uhjgacjpq7d06a96emnakfilgu1ee1on.apps.googleusercontent.com'

const LoginButton = ({ loginCallback, text, type }) => {
  const { loginUser } = useContext(UserContext)
  const successHandler = (res) => {
    const tokenId = res?.tokenId
    const requestBodyJson = {
      googleToken: tokenId,
    }

    fetch(domain + 'api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBodyJson),
    })
      .then((response) => response.json())
      .then((data) => {
        const result = res?.profileObj
        const token = data?.result.token
        try {
          const fullUser = { ...result, token }
          loginUser(fullUser)
          if (loginCallback) loginCallback(fullUser)
        } catch (e) {
          console.log(e)
        }
      })
  }

  const failureHandler = (errorData) => {
    console.log({ error: errorData })
  }

  return (
    <>
      <GoogleLogin
        clientId={googleClientId}
        buttonText="Login"
        render={(renderProps) => (
          <a
            className={`text-white text-center justify-center mx-auto cursor-pointer text-base font-medium px-4 ${
              type === 'navbar' ? 'py-3.5' : 'py-2'
            } flex flex-wrap items-center`}
            onClick={renderProps.onClick}
          >
            {text ? text : 'Login'}
          </a>
        )}
        onSuccess={successHandler}
        onFailure={failureHandler}
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}
export default LoginButton
