import domain from 'constants/domain'

const apiRequest = (requestMethod, relativeUrl, requestBodyJson = null) => {
  const p = new Promise((resolve, reject) => {
    const requestOptions = {
      method: requestMethod,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (requestBodyJson) {
      requestOptions.body = JSON.stringify(requestBodyJson)
    }
    fetch(`${domain}${relativeUrl}`, requestOptions).then(
      (response) => {
        try {
          const result = response.json()
          resolve(result)
        } catch (e) {
          console.log('Some fetch error: ', e)
          reject(e)
        }
      },
      (error) => reject(error)
    )
  })
  return p
}

export default apiRequest
