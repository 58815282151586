import { useContext, useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Image from 'next/image'
import classNames from '../utils/classNames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import UserContext from 'contexts/UserContext'
import LoginButton from './loginButton'
import ClickAwayListener from 'react-click-away-listener'
import AsyncSelect from 'react-select/async'
import apiRequest from 'utils/apiRequest'
import { debounce } from 'lodash'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Adventure sports', href: 'https://kahanchale.com', current: false },
  { name: 'About Us', href: '/about-us', current: false },
]

const HIDE_LOGIN = true

const fallbackImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAARVBMVEVHcEzx8fHDw8P7+/vU1NS7u7u/v7/Z2dnKysq+vr6+vr7n5+ff39/Nzc309PT09PT09PT09PS5ubn09PTf39/Hx8fT09NYbBeQAAAAEnRSTlMA+HoCG/aiDE7E2DD5wMSqiGPqRHjxAAABoElEQVRIx+2Wy5KEIAxFRRCIVPtA4P8/dRzb7pEAAat6MYu+KzfHPCCXdN1X/0JcKSGU4vdBoXsJMI4Astfizg+EluNg3xpGqUVrthou5CnQqoXVYLMCzath+8EWNPSV4EJaQpKs3IAlBYaIW2F3WhTrlbYqWaib97ZBfb7nemiBB51jFdgmQS7xNGkfnAs+Pe5Mp0eMuu2Q8/WO44rD9parVc3RMbntooCOi9NZ+y1SnPko6KxdDDs6754KjEPjfstSt3JVS/KGVGB0Tz4JV2quwCxm2R2YPqoaHIdGgZPBwiYSiOuJjyodyPCOzULiJlUb8e7AmfNVM0nG+ekGqRfkBgOPpHeviH9fxZGM8z5chDEXdiNK3CRjgde8k9sZNTzngLp0Q/A90bn3AmLjK9G5wJyfVRfYF503/fO5CVtRgXhuut+HDs8THszSQ7evQFBO+pk4EM+7eVCBN/Yw5FqxUPRSWajUWqTZWt+mzJzF2Wyatjid4mzWvHUHNMt04dm0GNU175+8U2Zd5mnXvKx3yBM/duZjY+b3t+avPq8f0t51INfC9K4AAAAASUVORK5CYII='

let loadOptions = (inputString, callback) => {
  if (!inputString) {
    callback([])
  }
  const queryUrl = `search-city?q=${inputString}`
  apiRequest('get', queryUrl)
    .then(
      (response) => response,
      (error) => {
        console.log(error)
      }
    )
    .then((jsonData) => {
      if (!jsonData) {
        callback([])
      } else {
        let locations = jsonData
        console.log(locations)
        locations = locations.map((location) => ({
          label: location.CityName,
          value: location.SlugifyName,
          data: location.CityName,
        }))
        callback(locations)
      }
    })
}

loadOptions = debounce(loadOptions, 500)

const SearchBar = () => {
  const router = useRouter()
  const [key, setKey] = useState(Date.now())
  const handleClose = () => {
    document.getElementById('close')?.click()
  }

  const handleSelect = (selectedOption) => {
    router.push(`/city/${selectedOption.value}`)
    handleClose()
  }

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      key={key}
      placeholder="Search for a city"
      className="w-96"
      onChange={handleSelect}
      styles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          boxShadow: 'none',
          borderRadius: '20px',
          backgroundColor: 'rgba(255, 255, 255)',
        }),
        input: (provided) => ({
          ...provided,
          color: 'black',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'black',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(255, 255, 255)',
        }),
      }}
    />
  )
}

export default function Navbar({ isTransparentNav }) {
  const { loggedUser, logoutUser } = useContext(UserContext)
  const [isTransparent, setIsTransparent] = useState(true)
  const router = useRouter()
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        setIsTransparent(false)
      } else {
        setIsTransparent(true)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
  const handleClickAway = () => {
    document.getElementById('close')?.click()
  }

  const redirectToHome = () => {
    router.push('/')
  }
  const defaultNavClass = 'fixed top-0 left-0 right-0 w-full duration-300 z-20'

  return (
    <Disclosure
      defaultOpen={false}
      as="nav"
      id="navbarTop"
      className={classNames(
        isTransparent
          ? `${isTransparentNav ? 'bg-transparent' : 'bg-themeDarkBlue shadow-2xl'}`
          : 'bg-themeDarkBlue shadow-2xl',
        defaultNavClass
      )}
    >
      {({ open }) => (
        <>
          <div className="max-w-6xl mx-auto px-2  lg:px-6">
            <div className="relative flex items-center justify-between h-20">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                <Disclosure.Button className="inline-flex items-center text-white justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6 text-white" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex-shrink-0 flex items-center cursor-pointer" onClick={redirectToHome}>
                  <Image src={'/images/logo-noname.png'} height={50} width={50} alt="Kahan Chale Logo" />

                  <h5 className="text-2xl pl-2 brand-name text-white font-semibold">Kahan Chale</h5>
                </div>

                <div className="hidden lg:block lg:ml-6 w-full">
                  <div className="flex space-x-4 h-full items-center justify-items-end justify-end">
                    <SearchBar />
                    {navigation.map((item, index) => {
                      return (
                        <>
                          {item.href.startsWith('http') ? (
                            <a
                              key={index}
                              href={item.href}
                              target="_blank"
                              className="text-white hover:text-white
                          px-4 py-3.5 rounded-md text-base font-normal"
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <Link key={index} href={item.href}>
                              <span
                                className="text-white hover:text-white
                          px-4 py-3.5 rounded-md text-base font-normal"
                                aria-current={item.current ? 'page' : undefined}
                              >
                                {item.name}
                              </span>
                            </Link>
                          )}
                        </>
                      )
                    })}
                    {!loggedUser && !HIDE_LOGIN && <LoginButton type="navbar" />}
                    {loggedUser && !HIDE_LOGIN && (
                      <>
                        <Link href="/my-trips">
                          <span
                            className="text-white hover:text-white
                        px-4 py-3.5 rounded-md text-base font-normal"
                          >
                            My Trips
                          </span>
                        </Link>
                        <a
                          onClick={logoutUser}
                          className="text-white hover:text-white
                          px-4 py-3.5 rounded-md text-base cursor-pointer font-normal"
                        >
                          Signout
                        </a>
                        <a
                          className="text-white  hover:text-white
                            px-4 py-3.5 rounded-md text-base font-normal cursor-pointer"
                        >
                          <a className="text-white focus:outline-none cursor-default flex text-base rounded-full">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={loggedUser?.imageUrl}
                              onError={(e) => ((e.currentTarget.onError = null), (e.currentTarget.src = fallbackImage))}
                              alt="Kahanchale, Google user Image"
                            />
                          </a>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Disclosure.Panel
              defaultOpen={true}
              className="lg:hidden bg-themeDarkBlue -mt-24 w-full z-30 absolute"
              style={{ height: '110vh' }}
            >
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md absolute right-5 top-5 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <div id="close">
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  </div>
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
              <div className="px-2 pt-2 pb-3">
                {loggedUser && (
                  <a
                    className="text-white hover:text-white
                            px-4  rounded-md text-base font-normal cursor-pointer"
                  >
                    <a className="bg-gray-800 focus:outline-none   w-max m-auto   cursor-default flex text-base rounded-full">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8 w-8 rounded-full" src={loggedUser?.imageUrl} alt="Kahanchale" />
                    </a>
                  </a>
                )}
                {!loggedUser && <div className="mt-8"></div>}

                {navigation.map((item, index) => (
                  <Link href={item.href} key={index}>
                    <span
                      className={classNames(
                        item.current ? ' text-white' : 'text-white  hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium text-center duration-300'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </span>
                  </Link>
                ))}
                {!loggedUser && !HIDE_LOGIN && (
                  <span className="block px-3 py-2 rounded-md text-base -mt-3 font-medium w-max mx-auto text-center">
                    <LoginButton type="navbar" />
                  </span>
                )}
                {loggedUser && (
                  <>
                    <Link href="/my-trips">
                      <span className="block px-3 text-white py-2 rounded-md text-base font-medium text-center duration-300">
                        My Trips
                      </span>
                    </Link>
                    <a
                      onClick={logoutUser}
                      className="block px-3 text-white py-2 rounded-md text-base cursor-pointer font-medium text-center duration-300"
                    >
                      Signout
                    </a>
                  </>
                )}
                <div className="flex justify-center mt-8">
                  <SearchBar />
                </div>
              </div>
            </Disclosure.Panel>
          </ClickAwayListener>
        </>
      )}
    </Disclosure>
  )
}
